
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { Mutations } from "@/store/enums/StoreEnums";
interface Filter {
  name: string;
  year: string;
}
interface SchoolarYears {
  _id: string;
  name: string;
}
export default defineComponent({
  name: "dropdown-1",
  components: {},
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const sy = window.localStorage.getItem("activeSchoolarYear");
    const activeYear = ref<string>(sy || "");
    const availavleSchoolarYears = ref<SchoolarYears[]>([
      {
        _id: "",
        name: "",
      },
    ]);

    const SchoolarYears = ref([
      {
        _id: "",
      },
    ]);

    ApiService.post("/schoolaryears/filter", {
      query: {},
      /* aggregation: [
        {
          $sort: {
            end: -1,
          },
        },
      ],*/
    }).then((res) => {
      const data = res.data.map((sc) => {
        const start = new Date(sc["start"]);
        const end = new Date(sc["end"]);
        return {
          ...sc,
          name:
            start.getMonth() +
            1 +
            "/" +
            start.getFullYear() +
            "-" +
            (end.getMonth() + 1) +
            "/" +
            end.getFullYear(),
        };
      });
      SchoolarYears.value = data;
      availavleSchoolarYears.value = data;
      //if (vendors.filter(e => e.Name === 'Magenic').length > 0) {
      /* vendors contains the element we're looking for */
    });

    const data = ref<Filter>({
      name: `${t("settings.chooseChild")}`,
      year: "",
    });

    const handleSettings = () => {
      setCurrentStudent();
      handleTrimesterChange();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    };

    const setCurrentStudent = () => {
      if (activeYear.value) {
        store.commit(
          Mutations.SET_SY,
          availavleSchoolarYears.value.filter(
            (sy) => sy._id == activeYear.value
          )[0].name
        );
        window.localStorage.setItem("activeSchoolarYear", activeYear.value);
      }
    };

    const isDateInRange = (date, startMonth, endMonth) => {
      let month = date.getMonth() + 1;
      return startMonth <= month && month <= endMonth;
    };

    let defaultTrimester = "3";
    const now = new Date();
    if (isDateInRange(now, 9, 12)) defaultTrimester = "1";
    else if (isDateInRange(now, 1, 3)) defaultTrimester = "2";

    const trimester = localStorage.getItem("trimester");
    const selectedTrimester = ref<string>(
      trimester ? trimester : defaultTrimester
    );

    const handleTrimesterChange = () => {
      if (!["0", "1", "2", "3"].includes(selectedTrimester.value))
        selectedTrimester.value = "0";
      localStorage.setItem("trimester", selectedTrimester.value);
      window.location.reload();
    };

    return {
      trimester,
      selectedTrimester,
      activeYear,
      t,
      handleSettings,
      availavleSchoolarYears,
      store,
      data,
    };
  },
});
