
import { computed, defineComponent, ref } from 'vue'
import { themeName, demo } from '@/core/helpers/documentation'
import { useI18n } from 'vue-i18n'
import ApiService from '@/core/services/ApiService'
import { Plus, Microphone, Select } from '@element-plus/icons-vue'
import { AxiosRequestConfig } from 'axios'
import store from '@/store'
import moment, { Moment } from 'moment'
// eslint-disable-next-line
declare var MediaRecorder: any

class WebRecording {
    public constraints: { audio: boolean; video?: boolean }
    public chunks: Blob[]
    public chunkTime: number
    // eslint-disable-next-line
    public recorder: any
    public isStoped: boolean
    public isReady: boolean

    constructor() {
        this.constraints = { audio: true }
        this.chunks = []
        this.chunkTime = 1000
        this.recorder = null
        this.isStoped = false
        this.isReady = false

        // eslint-disable-next-line
        const mediaNavigator =
            (window.navigator as any).getUserMedia ||
            (window.navigator as any).mozGetUserMedia ||
            (window.navigator as any).msGetUserMedia ||
            (window.navigator as any).webkitGetUserMedia
        if (!mediaNavigator) {
            throw Error('mediaNavigator not supported')
        }

        navigator.mediaDevices
            .getUserMedia(this.constraints)
            .then((stream) => {
                this.recorder = new MediaRecorder(stream)
                this.recorder.ondataavailable = (event) => {
                    this.chunks.push(event.data)
                    if (this.isReady) {
                        this.chunkReadyHandler(event.data)
                    }
                }
                this.recorder.onstop = () => {
                    const recordStream = new Blob(this.chunks, {
                        type: 'audio/mp3;codecs=opus',
                    })
                    if (this.isStoped) {
                        this.stopHandler(recordStream)
                    }
                }
            })
            .catch((e) => {
                throw 'web recording error: ' + e.message
            })
    }

    startRecord() {
        this.chunks = []
        if (!this.recorder) {
            throw Error('MediaRecorder not available')
        }
        this.recorder.start(this.chunkTime)
    }

    stopRecord() {
        if (!this.recorder) {
            throw Error('MediaRecorder not available')
        }
        this.recorder.stop()
    }

    onStop(handler: any) {
        this.isStoped = true
        this.stopHandler = handler
    }

    onChunkReady(handler) {
        this.isReady = false
        this.chunkReadyHandler = handler
    }

    // eslint-disable-next-line
    chunkReadyHandler(a: any) {}

    // eslint-disable-next-line
    stopHandler(b: any) {}

    setChunkTime(timeMs) {
        this.chunkTime = timeMs
    }
}

export default defineComponent({
    name: 'kt-explore',

    components: { Plus },

    setup() {
        const { t } = useI18n()
        const { locale } = useI18n()

        const uploadPercentage = ref(0)

        const text = ref('')
        const recorder = ref<WebRecording | null>(null)
        const stream = ref<string | null>(null)
        const isStarted = ref(false)
        const show = ref(true)

        const submitButtonRef = ref<null | HTMLButtonElement>(null)

        // eslint-disable-next-line
        const uploadFiles = ref<any>([])

        const updateUploadFiles = (file, files) => {
            uploadFiles.value = files
        }

        const currentUser = store.getters.currentUser

        const sendFeedback = () => {
            submitButtonRef.value!.disabled = true
            submitButtonRef.value?.setAttribute('data-kt-indicator', 'on')

            const data = new FormData()
            data.append('teacher', currentUser._id)
            data.append('text', text.value)

            if (stream.value) {
                const d = new Blob(recorder.value?.chunks, {
                    type: 'audio/mp3;codecs=opus',
                })
                data.append('audio', d, 'audio.mp3')
            }

            uploadFiles.value.forEach((file) => {
                data.append('images', file.raw, file.raw.name)
            })

            ApiService.put(`/teacherFeedback`, data as AxiosRequestConfig, {
                onUploadProgress: function (progressEvent) {
                    uploadPercentage.value = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    )
                },
            })
                .then(() => {
                    submitButtonRef.value!.disabled = false
                    submitButtonRef.value?.removeAttribute('data-kt-indicator')
                    const drawer = document.getElementById(
                        'kt_explore'
                    ) as HTMLElement
                    drawer.classList.remove('drawer-on')
                    document.body.removeAttribute('data-kt-drawer-explore')
                    document.body.removeAttribute('data-kt-drawer')
                    show.value = false
                })
                .catch((e) => {
                    console.log(e)
                })
        }

        function onReady(streamm) {
            const audioURL = window.URL.createObjectURL(streamm)
            stream.value = audioURL
        }

        function onEnable() {
            recorder.value = new WebRecording()
            recorder.value.onStop(onReady)
        }
        function onStart() {
            isStarted.value = true
            recorder.value?.startRecord()
        }
        function onStop() {
            isStarted.value = false
            recorder.value?.stopRecord()
        }

        const timer = ref<Moment>(moment())

        setInterval(() => {
            if (isStarted.value)
                timer.value = moment(timer.value.add(1, 'seconds'))
        }, 1000)

        const counter = computed(() => {
            return timer.value.format('mm:ss')
        })

        const customColorMethod = (percentage: number) => {
            if (percentage < 30) {
                return '#909399'
            }
            if (percentage < 70) {
                return '#e6a23c'
            }
            return '#67c23a'
        }

        return {
            t,
            text,
            themeName,
            demo,
            sendFeedback,
            locale,
            recorder,
            stream,
            isStarted,
            onReady,
            onEnable,
            onStart,
            onStop,
            Microphone,
            Select,
            updateUploadFiles,
            submitButtonRef,
            timer,
            moment,
            counter,
            show,
            uploadPercentage,
            customColorMethod,
        }
    },
})
