
import { defineComponent, computed, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Actions } from '@/store/enums/StoreEnums'
import moment from 'moment'
import { Socket } from 'socket.io-client'

export default defineComponent({
  name: 'kt-user-menu',
  components: {},
  setup() {
    const router = useRouter()
    const i18n = useI18n()
    const store = useStore()
    const socket: Socket = inject('socket') as Socket

    i18n.locale.value = localStorage.getItem('lang')
      ? (localStorage.getItem('lang') as string)
      : 'en'

    try {
      if (i18n.locale.value == 'ar') moment.locale('ar-tn')
      else moment.locale(i18n.locale.value)
    } catch (error) {
      moment.locale('en')
    }

    const currentUser = computed(() =>
      store.getters.currentUser.employee
        ? store.getters.currentUser
        : { employee: { email: '', firstName: '', lastName: '' } }
    )
    const currentPhoto = computed(() => store.getters.userPhotoURL)

    const countries = {
      fr: {
        flag: 'media/flags/france.svg',
        name: 'French',
      },

      ar: {
        flag: 'media/flags/tunisia.svg',
        name: 'Arabic',
      },
      en: {
        flag: 'media/flags/united-states.svg',
        name: 'English',
      },
    }

    const signOut = () => {
      store.dispatch(Actions.LOGOUT).then(() => {
        socket.emit('teacherLeave')
        socket.removeAllListeners()
        router.push({ name: 'sign-in' })
      })
    }

    const setLang = (lang) => {
      localStorage.setItem('lang', lang)
      i18n.locale.value = lang
      try {
        if (i18n.locale.value == 'ar') moment.locale('ar-tn')
        else moment.locale(i18n.locale.value)
      } catch (error) {
        moment.locale('en')
      }
    }

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang
    }

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value]
    })

    return {
      t: i18n.t,
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
      currentUser,
      currentPhoto,
    }
  },
})
