
import { defineComponent, ref } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "KTFooter",
  setup() {
    const store = useStore();
    const apiUrl = ref<string>(store.getters.serverConfigUrl.base_url + "/");

    const buildingData = ref<{
      name: string;
      adress: string;
      phone: string;
      headMaster: string;
      logo: string;
      base_url: string;
    }>({
      name: "",
      adress: "",
      phone: "",
      headMaster: "",
      logo: "",
      base_url: "",
    });

    ApiService.post(
      "/buildings/filter",
      {
        query: {
          dbName: store.getters.serverConfigUrl?.dbName,
        },
        aggregation: [
          {
            $project: {
              name: 1,
              adress: 1,
              phone: 1,
              headMaster: 1,
              logo: 1,
              base_url: 1,
            },
          },
        ],
      },
      {
        headers: { db: "Root" },
      }
    ).then(({ data }) => {
      if (data.length > 0) buildingData.value = data[0];
      else console.error(data);
    });
    return {
      footerWidthFluid,
      buildingData,
      apiUrl,
    };
  },
});
