
import { computed, defineComponent, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { MenuComponent } from '@/assets/ts/components'
import MainMenuConfig from '@/core/config/MainMenuConfig'
import { headerMenuIcons } from '@/core/helpers/config'
import { version } from '@/core/helpers/documentation'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'KTMenu',
  components: {},
  setup() {
    const { t, te } = useI18n()
    const route = useRoute()

    const store = useStore()

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1
    }

    const translate = (text) => {
      if (te(text)) {
        return t(text)
      } else {
        return text
      }
    }

    onMounted(() => {
      MenuComponent.reinitialization()
    })

    const edlearningAccess = computed(() => {
      if (!store.getters.serverConfigUrl) return {}
      return store.getters.serverConfigUrl.building.access.edLearning
    })

    const roles = computed(() => {
      if (store.getters.hasTeacherAccess) return ['teacher']
      if (store.getters.hasAdvisorAccess) return ['advisor']
      else return []
    })

    return {
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      translate,
      version,
      roles,
      edlearningAccess,
    }
  },
})
